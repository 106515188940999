import React from 'react';
import { Spinner, Text } from '@omnihub/ui';
import { useBlocklistEntries } from 'src/api/aegis/resources/blocklistEntries/blocklistEntries.hooks';

const Blocklist = (): React.ReactElement => {
  const { data, error, isLoading } = useBlocklistEntries();

  return (
    <div>
      <h1>Blocklist</h1>
      {isLoading && !error && (
        <>
          <Text>Loading...</Text>
          <Spinner />
        </>
      )}
      {!isLoading && error && <Text>Error...</Text>}
      {!isLoading && !error && (
        <ul>
          {data?.map((entry) => (
            <li key={entry.id}>{entry.id}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Blocklist;

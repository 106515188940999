import React from 'react';
import { Toaster } from 'react-hot-toast';

const NotificationsContainer = (): React.ReactElement => {
  return (
    <Toaster
      position="bottom-right"
    />
  );
};

export default NotificationsContainer;
import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { theme } from './ThemeProvider.constants';

const ThemeProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export { ThemeProvider };

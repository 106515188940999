import { useQuery } from 'react-query';

import api from '@api';
import monitoring from '@services/monitoring';

import type { QueryHookResult } from '../../client/types.ts';
import type { BlocklistEntry } from './blocklistEntries.types.ts';

export const useBlocklistEntries = (): QueryHookResult<BlocklistEntry[]> => {
  return useQuery({
    queryKey: 'aegisBlocklistEntries',
    queryFn: api.aegis.blocklistEntries.getAll,
    onError: monitoring.captureException,
  });
};

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from '@app/App';

import monitoring from '@services/monitoring';

import './stylesheets/global.scss';

monitoring.init();

// Add metadata to the window. The environment
// variables are generated in the pipeline on
// every deployment
window.tenhil = {
  ...(window.tenhil || {}),
  releaseDate: import.meta.env.VITE_APP_BUILD_DATE ? new Date(import.meta.env.VITE_APP_BUILD_DATE * 1000) : 'n/a',
  version: import.meta.env.VITE_APP_BUILD_VERSION || 'n/a',
};

createRoot(document.getElementById('root') as HTMLElement).render(<App />);

import type { StoreAction, StoreState } from '@store/store.types';

const reducer = (state: StoreState, action: StoreAction): StoreState => {
  switch (action.type) {
    case 'INCREASE_NUMBER': {
      return {
        ...state,
        number: state.number + action.payload.amount,
      };
    }
    case 'DECREASE_NUMBER': {
      return {
        ...state,
        number: state.number - action.payload.amount,
      };
    }
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

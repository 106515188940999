// Reason: we actually need to import Sentry here. The rest
// of the application should use Sentry through these methods
// eslint-disable-next-line no-restricted-imports
import * as Sentry from '@sentry/react';

import config from '@config';

/**
 * Initializes a monitoring service with its config.
 */
export const init = (): void => {
  Sentry.init(config.sentry);
};

/**
 * Sets a tag that can be used to filter events on our monitoring system.
 * @param key tag name
 * @param value tag value
 */
export const setTag = (key: string, value: string): void => {
  Sentry.setTag(key, value);
};

/**
 * Adds a breadcrumb used to track the user flow.
 * @param message a meaningful event description
 * @param category area this event appeared in
 */
export const addBreadcrumb = (message: string, category = 'navigation'): void => {
  Sentry.addBreadcrumb({
    category,
    message,
    level: 'info',
  });
};

/**
 * Converts the given string to kebab case and makes sure
 * it is not longer than 32 characters.
 * @param key tag key
 * @returns the given tag key as kebab case with maximum 32 characters
 * @private
 */
const normalizeTagKey = (key: string): string => {
  if (!key) {
    return 'invalid-key';
  }

  const kebabCaseChars = key.split('').map((letter, i) => {
    const prefix = i !== 0 ? '-' : '';

    return letter.toUpperCase() === letter ? `${prefix}${letter.toLowerCase()}` : letter;
  });

  // The braces are important, otherwise slice doesn't work
  return kebabCaseChars.join('').slice(0, 32);
};

/**
 * Sends an error object to Sentry with optional additional tags.
 * Namespace, app version and ID of the current user are added
 * automatically to the event and don't need to be sent manually.
 * @param error {Error} error object that should be sent to Sentry
 * @param additionalTags {object|null} optional additional tags as key string value pairs
 * @returns {string|undefined} the Sentry ticket ID
 */
export const captureException = (error: Error, additionalTags?: Record<string, string>): string => {
  let response;

  if (additionalTags && !!Object.keys(additionalTags).length) {
    const stringifiedTags: Record<string, string> = {};

    // Keys in custom tags cannot be longer than 32 characters and values
    // must be strings with a maximum of 200 characters.
    // @see https://docs.sentry.io/platforms/python/guides/logging/enriching-events/tags/
    for (const [key, value] of Object.entries(additionalTags)) {
      stringifiedTags[normalizeTagKey(key)] = value.slice(0, 200);
    }

    response = Sentry.captureException(error, { tags: stringifiedTags });
  } else {
    response = Sentry.captureException(error);
  }

  return response;
};

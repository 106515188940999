import React from 'react';

import { useStore } from '@store';

import logo from '@assets/logos/tenhil.webp';

import styling from './Menu.module.scss';

const Menu = (): React.ReactElement => {
  const { store } = useStore();

  return (
    <div className={styling.container}>
      <img className={styling.logo} src={logo} alt="tenhil wordmark" />
      {store.user && <img className={styling.avatar} src={store.user.avatar} alt="user avatar" />}
    </div>
  );
};

export default Menu;

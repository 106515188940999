import React, { createContext, useContext, useMemo, useReducer } from 'react';
// Reason: We actually need the labels here to add it
// to the store through which they should be accessed
// eslint-disable-next-line no-restricted-imports
import { labelsEn } from '@labels/en';

import type { Props, StoreContext, StoreState } from '@store/store.types';

import reducer from './reducer';

const initialState: StoreState = {
  number: 1,
  labels: labelsEn,
  user: null,
};

const Context = createContext<StoreContext>({
  store: initialState,
  dispatch: () => {},
});

// We exceptionally disable the rule here, as it won't cause any issues
// in this scenario so that the code is structured in a more developer
// friendly way
// eslint-disable-next-line react-refresh/only-export-components
export const useStore = (): StoreContext => useContext(Context);

export const StoreProvider = ({ children }: Props): React.ReactElement => {
  // Store
  const [state, dispatch] = useReducer(reducer, initialState);

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      store: state,
      dispatch,
    }),
    [state, dispatch],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme, responsiveFontSizes } from '@mui/material';

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  cssVariables: {
    cssVarPrefix: 'tenhil',
  },
  palette: {
    primary: {
      main: '#DC4405',
      50: '#FDC3AB',
    },
    secondary: {
      main: '#6B696B',
    },
    background: {
      default: '#F4F4F4',
    },
    grey: {
      50: '#F4F4F4',
      200: '#D4D4D4',
      700: '#6B696B',
      900: '#2B2C2E',
    },
  },
  typography: {
    fontFamily: '"Sofia Pro", monospace, sans-serif',
    h1: {
      fontSize: '3rem',
      lineHeight: 1,
      fontWeight: 400,
    },
    h2: {
      fontSize: '2.25rem',
      lineHeight: 1.11,
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.875rem',
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: 1.33,
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: 1.4,
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: 1.55,
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ':root': {
          borderRadius: '8px',
        },
      },
    },
  },
  shadows: [
    'none',
    '0 1px 2px rgba(43, 44, 46, 0.05), 0 2px 4px rgba(43, 44, 46, 0.1)',
    '0 2px 4px rgba(43, 44, 46, 0.05), 0 4px 8px rgba(43, 44, 46, 0.1)',
    '0 3px 6px rgba(43, 44, 46, 0.05), 0 6px 12px rgba(43, 44, 46, 0.1)',
    '0 4px 8px rgba(43, 44, 46, 0.05), 0 8px 16px rgba(43, 44, 46, 0.1)',
    '0 5px 10px rgba(43, 44, 46, 0.05), 0 10px 20px rgba(43, 44, 46, 0.1)',
    '0 6px 12px rgba(43, 44, 46, 0.05), 0 12px 24px rgba(43, 44, 46, 0.1)',
    '0 7px 14px rgba(43, 44, 46, 0.05), 0 14px 28px rgba(43, 44, 46, 0.1)',
    '0 8px 16px rgba(43, 44, 46, 0.05), 0 16px 32px rgba(43, 44, 46, 0.1)',
    '0 9px 18px rgba(43, 44, 46, 0.05), 0 18px 36px rgba(43, 44, 46, 0.1)',
    '0 10px 20px rgba(43, 44, 46, 0.05), 0 20px 40px rgba(43, 44, 46, 0.1)',
    '0 11px 22px rgba(43, 44, 46, 0.05), 0 22px 44px rgba(43, 44, 46, 0.1)',
    '0 12px 24px rgba(43, 44, 46, 0.05), 0 24px 48px rgba(43, 44, 46, 0.1)',
    '0 13px 26px rgba(43, 44, 46, 0.05), 0 26px 52px rgba(43, 44, 46, 0.1)',
    '0 14px 28px rgba(43, 44, 46, 0.05), 0 28px 56px rgba(43, 44, 46, 0.1)',
    '0 15px 30px rgba(43, 44, 46, 0.05), 0 30px 60px rgba(43, 44, 46, 0.1)',
    '0 16px 32px rgba(43, 44, 46, 0.05), 0 32px 64px rgba(43, 44, 46, 0.1)',
    '0 17px 34px rgba(43, 44, 46, 0.05), 0 34px 68px rgba(43, 44, 46, 0.1)',
    '0 18px 36px rgba(43, 44, 46, 0.05), 0 36px 72px rgba(43, 44, 46, 0.1)',
    '0 19px 38px rgba(43, 44, 46, 0.05), 0 38px 76px rgba(43, 44, 46, 0.1)',
    '0 20px 40px rgba(43, 44, 46, 0.05), 0 40px 80px rgba(43, 44, 46, 0.1)',
    '0 21px 42px rgba(43, 44, 46, 0.05), 0 42px 84px rgba(43, 44, 46, 0.1)',
    '0 22px 44px rgba(43, 44, 46, 0.05), 0 44px 88px rgba(43, 44, 46, 0.1)',
    '0 23px 46px rgba(43, 44, 46, 0.05), 0 46px 92px rgba(43, 44, 46, 0.1)',
    '0 24px 48px rgba(43, 44, 46, 0.05), 0 48px 96px rgba(43, 44, 46, 0.1)',
  ],
  spacing: (factor: number) => `${0.5 * factor}rem`,
});

export const theme = responsiveFontSizes(baseTheme);

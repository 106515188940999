import React from 'react';
import { Typography as MuiText } from '@mui/material';

import type { HeadingProps } from './Heading.types';

export const Heading = ({ children, variant, align, gutterBottom, noWrap }: HeadingProps): React.ReactElement => {
  return (
    <MuiText variant={variant || 'h3'} align={align} gutterBottom={gutterBottom} noWrap={noWrap}>
      {children}
    </MuiText>
  );
};

/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Aegis - Blocklist
 * Staff tool to manage various block criterias
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `complaint` - Complaint
* `politics` - Politics
 */
export enum EntryReadReason {
  complaint= 'complaint',
  politics= 'politics',

}
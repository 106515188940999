import React from 'react';
import { Box } from '@mui/material';

import type { SurfaceProps } from './Surface.types';

export const Surface = ({ children }: SurfaceProps): React.ReactElement => {
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '0.8rem',
        borderStyle: 'solid',
        borderWidth: 1,
        width: '100%',
        overflow: 'hidden',
        backgroundColor: 'white',
        borderColor: 'grey.200',
        padding: '2rem',
        zIndex: '1',
      }}
    >
      {children}
    </Box>
  );
};

import React from 'react';

import styling from './SmallScreenWrapper.module.scss';

import type { SmallScreenWrapperProps } from './SmallScreenWrapper.types';

const SmallScreenWrapper = ({ children }: SmallScreenWrapperProps): React.ReactElement => (
  <>
    <div className={styling.container}>
      <h1>App not available for small displays</h1>
      <p>Unfortunately, the app is not optimized for smaller displays. Please resize your window for the best experience.</p>
    </div>
    <div className={styling.childcontainer}>{children}</div>
  </>
);

export default SmallScreenWrapper;

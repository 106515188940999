import React, { useCallback, useEffect, useState } from 'react';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { Center, Spinner } from '@omnihub/ui';

import ErrorScreen from '@pages/errorScreen/ErrorScreen.tsx';

import config from '@config';
import logging from '@services/logging';
import monitoring from '@services/monitoring';
import storage from '@services/storage';
import { useStore } from '@store';

const AuthGuard = ({ children }: { children: React.ReactElement }): React.ReactElement => {
  // Store
  const { dispatch } = useStore();

  // State
  const [{ error, isLoading }, setState] = useState({
    error: '',
    isLoading: true,
  });

  /**
   * Redirects to the Auth0 login page if the user
   * is not authenticated. It handles the Auth0
   * callback by using the code provided as query
   * parameter to obtain the Aegis token as well
   * as the user data that it stores in the global
   * store.
   */
  const handleLogin = useCallback(async () => {
    try {
      const client = await createAuth0Client({
        domain: config.auth.domain,
        clientId: config.auth.clientId,
        authorizationParams: {
          // eslint-disable-next-line @typescript-eslint/naming-convention,camelcase
          redirect_uri: window.location.origin,
          organization: config.auth.organization,
          audience: config.auth.aegisAudience,
        },
      });

      const query = window.location.search;

      if (query.includes('code=')) {
        await client.handleRedirectCallback();
        window.history.replaceState({}, document.title, '/'); // Remove query parameters
      }

      const isAuthenticated = await client.isAuthenticated();

      if (!isAuthenticated) {
        await client.loginWithRedirect();

        return;
      }

      const [aegisToken, user] = await Promise.all([
        client.getTokenSilently({ authorizationParams: { audience: config.auth.aegisAudience } }),
        client.getUser(),
      ]);

      storage.local.setAegisJWT(aegisToken);

      dispatch({
        type: 'SET_USER',
        payload: {
          email: user?.email || '',
          avatar: user?.picture || '',
        },
      });

      setState(prevState => ({ ...prevState, error: '', isLoading: false }));

    } catch (error) {
      logging.error(error);
      monitoring.captureException(error as Error);
      setState(prevState => ({ ...prevState, error: (error as Error).message, isLoading: false }));
    }
  }, [dispatch]);

  /**
   * Executes the handleLogin function whenever
   * it changes.
   */
  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  // Show a spinner while loading to prevent
  // child components from crashing due to
  // missing data such as the Aegis token
  if (isLoading) {
    return (
      <Center>
        <Spinner size="medium" />
      </Center>
    );
  }

  // Show a generic error if something went wrong
  if (error) {
    return <ErrorScreen />;
  }

  return children;
};

export default AuthGuard;
import React from 'react';
import { FormControl, InputLabel, Select as MuiSelect } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';

import type { SelectProps } from './Select.types';

export const Select = ({ value, onChange, options, label }: SelectProps): React.ReactElement => {
  return (
    <FormControl fullWidth>
      <InputLabel id="select-label-id">{label}</InputLabel>
      <MuiSelect
        labelId="select-label-id"
        id="select"
        value={value}
        label={label}
        onChange={(event) => onChange?.(event.target.value)}
        IconComponent={KeyboardArrowDownIcon}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

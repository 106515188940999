import React from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel, Typography } from '@mui/material';

import type { CheckboxProps } from './Checkbox.types';

export const Checkbox = ({ checked, onChange, label }: CheckboxProps): React.ReactElement => {
  return (
    <FormControlLabel
      control={<MuiCheckbox checked={checked} onChange={onChange} size="large" />}
      label={
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
          }}
        >
          {label}
        </Typography>
      }
    />
  );
};

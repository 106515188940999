/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Aegis - Blocklist
 * Staff tool to manage various block criterias
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `job_id` - Job Id
* `importer_source` - Importer Source
* `ad_customer` - Ad Customer
* `ad_customer_partial` - Ad Customer Partial
* `ad_customer_exact` - Ad Customer Exact
* `contract_customer` - Contract Customer
* `product` - Product
 */
export enum CriteriaReadName {
  job_id= 'job_id',
  importer_source= 'importer_source',
  ad_customer= 'ad_customer',
  ad_customer_partial= 'ad_customer_partial',
  ad_customer_exact= 'ad_customer_exact',
  contract_customer= 'contract_customer',
  product= 'product',

}
import config from '@config';
import logging from '@services/logging';
import storage from '@services/storage';

import type { FetchOptions, HttpMethod, PathWithQueryOptions, ResponseErrorBody } from './types';

// This map will keep all abort request controllers
// in memory. They will be optionally set when a
// request is being made and can be removed
// through the abortRequestSafe function
const ABORT_REQUEST_CONTROLLERS: Map<string, AbortController> = new Map();

/**
 * Aborts a pending request made to the Aegis backend.
 * @param key a unique identifier of your choice. E.g. 'all-customers'
 * @param reason optional reason for aborting the request, which can be relevant for logging
 */
export const abortRequestSafe = (key: string, reason?: string): void => {
  ABORT_REQUEST_CONTROLLERS.get(key)?.abort(reason);
  ABORT_REQUEST_CONTROLLERS.delete(key);
};

/**
 * Creates an abort controller and returns the signal. The provided
 * signal key name can be used to then abort the request later.
 * @param key a unique identifier of your choice. E.g. 'all-customers'
 * @returns signal
 */
const getSignal = (key: string): AbortSignal => {
  abortRequestSafe(key);

  const newController = new AbortController();
  ABORT_REQUEST_CONTROLLERS.set(key, newController);

  return newController.signal;
};

/**
 * Constructs the URL based on the given path and query params.
 * @param options desired options
 * @returns constructed URL
 */
const getUrlPathWithQuery = (options: PathWithQueryOptions): string => {
  const { path, query = {} } = options;

  const url = new URL(`/api${path.endsWith('/') ? path : `${path}/`}`, config.connectivity.aegisAPIUrl);

  const searchParams = new URLSearchParams({
    ...Object.fromEntries(url.searchParams),
    ...query,
  });

  const searchParamsString = searchParams.toString();

  return `${url.toString()}${searchParamsString ? `?${searchParamsString}` : ''}`;
};

/**
 * Handles request errors by logging the error and throwing
 * a clean error message.
 * @param res response object
 * @param method HTTP method
 * @param path URL path
 * @returns the response body as JSON
 */
const handleResultOrError = async (res: Response, method: HttpMethod, path: string): Promise<unknown> => {
  if (!res.ok) {
    const responseErrorBody = (await res.json()) as ResponseErrorBody;
    logging.error(`[AEGIS API Error] [${method} ${path}]:\n ${responseErrorBody.message}`);

    throw new Error(responseErrorBody.translationCode || responseErrorBody.message);
  }

  if (res.status === 204) {
    return {};
  }

  return res.json();
};

/**
 * Makes HTTP request based on the given options.
 * @param path URL path of the requests
 * @param options request options
 * @returns response body as JSON
 */
export const customFetch = async (path: string, options?: FetchOptions): Promise<unknown> => {
  const { method = 'GET', headers, query, body, signalKey, ...rest } = options || {};

  const addBody = ['POST', 'PUT', 'PATCH'].includes(method);

  const isFormData = body instanceof FormData;

  const res = await fetch(getUrlPathWithQuery({ path, query }), {
    method,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ...(!isFormData && { 'Content-Type': 'application/json; charset=utf-8' }),
      ...headers,
      authorization: `Bearer ${storage.local.getAegisJWT()}`,
    },
    ...(signalKey && { signal: getSignal(signalKey) }),
    ...(addBody && { body: isFormData ? body : JSON.stringify(body) }),
    ...rest,
  });

  return handleResultOrError(res, method, path);
};

import React from 'react';
import { Button as MuiButton } from '@mui/material';

import type { IconButtonProps } from './IconButton.types';

export const IconButton = ({ icon, disabled, disableElevation, variant, fontSize, color }: IconButtonProps): React.ReactElement => {
  return (
    <MuiButton
      disabled={disabled}
      disableElevation={disableElevation}
      variant={variant || 'contained'}
      sx={{ p: 0, width: 48, height: 48, minWidth: 48, minHeight: 48, fontSize: fontSize || '3.5rem' }}
      color={color || 'primary'}
    >
      {icon}
    </MuiButton>
  );
};

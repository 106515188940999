import React from 'react';
import { Button as MuiButton } from '@mui/material';

import type { ButtonProps } from './Button.types';

export const Button = ({ children, disabled, disableElevation, fullWidth, size, variant, color }: ButtonProps): React.ReactElement => {
  return (
    <MuiButton
      disabled={disabled}
      disableElevation={disableElevation}
      fullWidth={fullWidth}
      size={size || 'medium'}
      variant={variant || 'contained'}
      color={color || 'primary'}
    >
      {children}
    </MuiButton>
  );
};

import config from '@config';

/**
 * Logs an error.
 * @param args {...*} arguments that are being passed to console.error()
 */
const error = (...args: unknown[]): void => {
  const isLocal = window.location.hostname === 'localhost';

  if (isLocal || config.logging.enableErrorLogsInProd) {
    console.error(...args);
  }
};

/**
 * Logs a warning.
 * @param args {...*} arguments that are being passed to console.warn()
 */
const warn = (...args: unknown[]): void => {
  const isLocal = window.location.hostname === 'localhost';

  if (isLocal || config.logging.enableWarningLogsInProd) {
    console.warn(...args);
  }
};

/**
 * Logs an info.
 * @param args {...*} arguments that are being passed to console.info()
 */
const info = (...args: unknown[]): void => {
  const isLocal = window.location.hostname === 'localhost';

  if (isLocal || config.logging.enableInfoLogsInProd) {
    console.info(...args);
  }
};

export { error, info, warn };

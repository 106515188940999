import React from 'react';
import { Link } from 'react-router-dom';

import styling from './SidebarButton.module.scss';

import type { SidebarButtonProps } from './SidebarButton.types';

export const SidebarButton = ({ to, icon, isActive }: SidebarButtonProps): React.ReactElement => {
  return (
    <Link to={to} className={`${styling.button} ${isActive ? styling.active : ''}`}>
      {icon}
    </Link>
  );
};

import React from 'react';
import { Typography as MuiText } from '@mui/material';

import type { TextProps, TextSize, TextWeight } from './Text.types';

export const Text = ({ children, component = 'p', align, size = 'm', weight = 'light', gutterBottom, noWrap }: TextProps): React.ReactElement => {
  // Map the text sizes to their respective line heights
  const lineHeights: Record<TextSize, string> = {
    xs: '1.33',
    s: '1.42',
    m: '1.5',
  };

  // Map the text sizes to their respective font sizes in rem
  const fontSizes: Record<TextSize, string> = {
    xs: '0.75rem',
    s: '0.875rem',
    m: '1rem',
  };

  // Map the font weights to their respective numeric font weights
  const fontWeights: Record<TextWeight, string> = {
    light: '300',
    regular: '400',
    semibold: '600',
  };

  return (
    <MuiText
      component={component}
      align={align}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      sx={{
        lineHeight: lineHeights[size],
        fontSize: fontSizes[size],
        fontWeight: fontWeights[weight],
      }}
    >
      {children}
    </MuiText>
  );
};

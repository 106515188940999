import React from 'react';
import { Outlet } from 'react-router-dom';

import Menu from '@layout/menu/Menu.tsx';
import Sidebar from '@layout/sidebar/Sidebar.tsx';

import styling from './Layout.module.scss';

const Layout = (): React.ReactElement => {
  return (
    <div className={styling.container}>
      <Menu />

      <div className={styling.wrapper}>
        <Sidebar />

        <main className={styling.main}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;

import React from 'react';
import { Heading, Text } from '@omnihub/ui';

import { useStore } from '@store';

import styling from './ErrorScreen.module.scss';

const ErrorScreen = (): React.ReactElement => {
  // Store
  const { store } = useStore();

  // Determine labels for translation
  const labels = store.labels;

  // Get the ticket ID from the query params. The ticket ID equals
  // a Sentry event ID, which means we can search the corresponding
  // error event details in sentry with this ID. The ticket ID
  // is the returned string from monitoring.captureException()
  // https://sentry.statec.io/organizations/tenhil/issues/?project=123&query=id%<ticket ID>
  const params = new URLSearchParams(location.search);

  const ticketId = params.get('ticketId');

  return (
    <div className={styling.container}>
      <div className={styling.box}>
        <Heading gutterBottom>
          {labels.error_screen_headline}
        </Heading>

        <Text>
          {labels.error_screen_caption}
        </Text>

        <div className={styling.ticket} hidden={!ticketId}>
          <Text size="s" weight="semibold">
            Ticket ID: {ticketId}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio as MuiRadio, RadioGroup } from '@mui/material';

import type { RadioProps } from './Radio.types';

export const Radio = ({ label, direction = 'row', options }: RadioProps): React.ReactElement => {
  const defaultValue = options.find((option) => option.default)?.value || options[0].value;

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup defaultValue={defaultValue} row={direction === 'row'}>
        {options.map(({ value, label, disabled }) => (
          <FormControlLabel key={value} value={value} control={<MuiRadio />} label={label} disabled={disabled} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

import React from 'react';
import { Navigate, Route, Routes as ReactDOMRoutes } from 'react-router-dom';

import Layout from '@layout/Layout';
import Blocklist from '@pages/blocklist/Blocklist.tsx';
import ErrorScreen from '@pages/errorScreen/ErrorScreen.tsx';
import Example from '@pages/example/Example.tsx';
import NewEntry from '@pages/newEntry/NewEntry.tsx';

const Routes = (): React.ReactElement => {
  return (
    <ReactDOMRoutes>
      <Route element={<Layout />}>
        <Route path="/blocklist" element={<Blocklist />} />
        <Route path="/error" element={<ErrorScreen />} />
        <Route path="/new" element={<NewEntry />} />
        <Route path="/example" element={<Example />} />
      </Route>

      {/* If none of the routes match, we redirect to the /dashboard */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </ReactDOMRoutes>
  );
};

export default Routes;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@omnihub/ui';
import { PenToSquare, TableList } from '@omnihub/ui/icons/regular';

import { SidebarButton } from './sidebarButton/SidebarButton';

import styling from './Sidebar.module.scss';

const Sidebar = (): React.ReactElement => {
  const location = useLocation();

  return (
    <aside className={styling.container}>
      <div className={styling.wrapper}>
        <Grid gap="0" padding="0" flexDirection="column" sizeEvenly={false}>
          <SidebarButton to="/" icon={<TableList />} isActive={location.pathname === '/'} />
          <SidebarButton to="/new" icon={<PenToSquare />} isActive={location.pathname === '/new'} />
        </Grid>
      </div>
    </aside>
  );
};

export default Sidebar;

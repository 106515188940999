import React from 'react';
import { CircularProgress } from '@mui/material';

import type { SpinnerProps } from './Spinner.types';

export const Spinner = ({ size = 'small', color }: SpinnerProps): React.ReactElement => {
  let sz: number;
  switch (size) {
    case 'xlarge': {
      sz = 128;
      break;
    }
    case 'large': {
      sz = 96;
      break;
    }
    case 'medium': {
      sz = 48;
      break;
    }
    default: {
      sz = 24;
    }
  }

  return <CircularProgress size={sz} color={color || 'inherit'} />;
};

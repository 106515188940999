import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@omnihub/ui';

import SmallScreenWrapper from '@components/containers/smallScreenWrapper/SmallScreenWrapper';
import AuthGuard from '@containers/authGuard/AuthGuard.tsx';
import ReactQueryProvider from '@containers/reactQueryProvider/ReactQueryProvider';
import Routes from '@routes/Routes';

import NotificationsContainer from '@services/notifications/NotificationsContainer.tsx';
import { StoreProvider } from '@store';

const App = (): React.ReactElement => {
  return (
    <ThemeProvider>
      <StoreProvider>
        <AuthGuard>
          <SmallScreenWrapper>
            <BrowserRouter>
              <ReactQueryProvider>
                <Routes />
                <NotificationsContainer />
              </ReactQueryProvider>
            </BrowserRouter>
          </SmallScreenWrapper>
        </AuthGuard>
      </StoreProvider>
    </ThemeProvider>
  );
};

export default App;

import React from 'react';

import { useStore } from '@store';

import styling from './ExampleChildComponent.module.scss';

const ExampleChildComponent = (): React.ReactElement => {
  // Store
  const { store, dispatch } = useStore();

  // Determine labels derived from store
  const labels = store.labels;

  return (
    <div className={styling.container}>
      <div className={styling.text}>
        {labels.dashboard_example_child_component_text} <b>{store.number}</b>.
      </div>

      <div>
        <button onClick={() => dispatch({ type: 'INCREASE_NUMBER', payload: { amount: 1 } })}>{labels.dashboard_example_child_component_inc}</button>

        <button onClick={() => dispatch({ type: 'DECREASE_NUMBER', payload: { amount: 1 } })}>{labels.dashboard_example_child_component_dec}</button>
      </div>
    </div>
  );
};

export default ExampleChildComponent;

import React from 'react';

const NewEntry = (): React.ReactElement => {
  return (
    <div>
      <h1>New Entry</h1>
    </div>
  );
};

export default NewEntry;

import auth from './auth.config';
import connectivity from './connectivity.config';
import logging from './logging.config';
import sentry from './sentry.config';

const config = {
  connectivity,
  logging,
  sentry,
  auth,
};

export default config;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonWithIcon,
  Checkbox,
  Grid,
  Heading,
  IconButton,
  Input,
  Radio,
  Select,
  Surface,
  Table,
  Text,
} from '@omnihub/ui';

import ExampleChildComponent from './exampleChildComponent/ExampleChildComponent';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import SearchIcon from '@mui/icons-material/Search';

import monitoring from '@services/monitoring';
import notifications from '@services/notifications';

const Example = (): React.ReactElement => {
  /**
   * Removed the given location from the blocklist.
   * @param locationId ID of the location to delete
   */
  const removeFromBlocklist = (locationId: string): void => {
    try {
      monitoring.addBreadcrumb(`user removed location with ID: ${locationId}`);

      throw new Error('some error while fetching');
    } catch (error) {
      monitoring.captureException(error as Error, { locationId });
    }
  };

  const [selectValue, setSelectValue] = useState('');

  return (
    <Surface>
      <Link to="/">HOME</Link>
      <Heading variant="h1">Example Page</Heading>
      <Heading>Radio Button UI Components</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Radio
          label="Target - Row (default)"
          options={[
            { value: 'backfill', label: 'Backfill', default: true },
            { value: 'export', label: 'Export' },
          ]}
        />
        <div>
          <Radio
            label="Target - Column"
            direction="column"
            options={[
              { value: 'backfill', label: 'Backfill', default: true },
              { value: 'export', label: 'Export' },
            ]}
          />
        </div>
        <Text>Label is optional and can be omitted (see below)</Text>
        <Radio
          options={[
            { value: 'backfill', label: 'Backfill', default: true },
            { value: 'export', label: 'Export' },
          ]}
        />
      </div>
      <Heading>CheckBox UI Components</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Checkbox label="test label" />
        <Text>Label is optional and can be omitted (see below)</Text>
        <Checkbox /> <Checkbox />
      </div>
      <Heading>Heading UI Components</Heading>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', margin: '3rem 0' }}>
        <Heading>Default is h3</Heading>
        <Heading variant="h1">Heading 1</Heading>
        <Heading variant="h2">Heading 2</Heading>
        <Heading variant="h3">Heading 3</Heading>
        <Heading variant="h4">Heading 4</Heading>
        <Heading variant="h5">Heading 5</Heading>
        <Heading variant="h6">Heading 6</Heading>
      </div>
      <Heading>Button UI Components</Heading>
      <div style={{ display: 'flex', gap: '1rem', margin: '3rem 0' }}>
        <Button>No Props Only Default Values</Button>
        <Button size="small">Hello</Button>
        <Button size="medium">Hello</Button>
        <Button size="large">Hello</Button>
        <Button color="secondary" size="large">
          Hello
        </Button>
        <Button variant="outlined">Outlined</Button>
        <Button color="secondary" variant="outlined">
          Outlined
        </Button>
        <Button variant="text">Text</Button>
        <Button disabled>Disabled</Button>
        <Button disableElevation>Disabled Elevation</Button>
      </div>
      <Heading>ButtonWithIcon UI Components</Heading>
      <div style={{ display: 'flex', gap: '1rem', margin: '3rem 0' }}>
        <ButtonWithIcon size="large" startIcon={<AgricultureIcon />}>
          ButtonWithIcon With Icon Start
        </ButtonWithIcon>
      </div>
      <Heading>IconButton UI Components</Heading>
      <div style={{ display: 'flex', gap: '1rem', margin: '3rem 0' }}>
        <IconButton fontSize="36px" icon={<SearchIcon fontSize="large" />} />
        <IconButton color="secondary" fontSize="36px" icon={<SearchIcon />} />
        <IconButton icon={<SearchIcon fontSize="small" />} />
        <IconButton variant="outlined" icon={<SearchIcon />} />
        <IconButton color="secondary" variant="outlined" icon={<SearchIcon />} />
      </div>
      <Heading>Select UI Component</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Select
          options={[
            { value: '1', label: '1' },
            { value: '2', label: '2' },
          ]}
          value={selectValue}
          onChange={(e: string) => setSelectValue(e)}
          label="Test Label"
        />
      </div>
      <Heading>Example Child Component</Heading>
      <ExampleChildComponent />
      <div style={{ margin: '3rem 0' }}>
        <button onClick={() => monitoring.addBreadcrumb('user clicked button #1')}>Button #1</button>

        <button
          onClick={() => {
            monitoring.addBreadcrumb('user clicked button #2');
            throw new Error('this is a fancy error!');
          }}
        >
          Button #2
        </button>

        <button onClick={() => removeFromBlocklist('12345')}>Button #3</button>
      </div>
      <Heading>Text UI Component</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Text>This is the text component</Text>
      </div>
      <Heading>Input UI Component</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Input icon={<AgricultureIcon />} label="Criteria" placeholder="Suchbegriff" />
      </div>
      <div style={{ margin: '3rem 0' }}>
        <Input placeholder="No Icon No Label" />
      </div>

      <button onClick={() => notifications.info('Great!', 'This is an info toast')}>Toast me for info :)</button>

      <button onClick={() => notifications.success('Great!', 'This is a success toast')}>Toast me for success :)</button>

      <button onClick={() => notifications.alert('Great!', 'This is an alert toast')}>
        Toast me for alert :)
      </button>

      <Heading>Grid UI Component with 1 child</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Grid>
          <Input placeholder="Input 1" />
        </Grid>
      </div>
      <Heading>Grid UI Component with 2 children and space-between</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Grid size={2} sizeEvenly={false} justifyContent="space-between">
          <Input placeholder="Input 1" />
          <Input placeholder="Input 2" />
        </Grid>
      </div>
      <Heading>Grid UI Component with 3 children</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Grid>
          <Input placeholder="Input 1" />
          <Input placeholder="Input 2" />
          <Checkbox label="test label" />
        </Grid>
      </div>
      <Heading>Grid UI Component with 4 children</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Grid>
          <Input placeholder="Input 1" />
          <Input placeholder="Input 2" />
          <Input placeholder="Input 3" />
          <Input placeholder="Input 4" />
        </Grid>
      </div>
      <Heading>Grid Real Example</Heading>
      <div style={{ margin: '3rem 0' }}>
        <Heading>Backfill (Portals)</Heading>
        <Grid>
          <Input icon={<AgricultureIcon />} placeholder="Suchbegriff" />
          <Select
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
            ]}
            value={selectValue}
            onChange={(e: string) => setSelectValue(e)}
            label="Portals (backfill)"
          />
        </Grid>
        <Grid>
          <Select
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
            ]}
            value={selectValue}
            onChange={(e: string) => setSelectValue(e)}
            label="Criteria"
          />
          <Select
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
            ]}
            value={selectValue}
            onChange={(e: string) => setSelectValue(e)}
            label="Value"
          />
          <Select
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
            ]}
            value={selectValue}
            onChange={(e: string) => setSelectValue(e)}
            label="Reason"
          />
          <Select
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
            ]}
            value={selectValue}
            onChange={(e: string) => setSelectValue(e)}
            label="Blocklisted by"
          />
          <Input icon={<AgricultureIcon />} placeholder="Zeitraum" />
        </Grid>
        <Grid sizeEvenly={false} justifyContent="space-between">
          <Grid justifyContent="flex-start">
            <Button size="medium">Cancel</Button>
          </Grid>
          <Grid sizeEvenly={false} justifyContent="flex-end">
            <IconButton icon={<SearchIcon />} />
            <Button size="medium">Save new item</Button>
            <Button size="medium">Reset</Button>
          </Grid>
        </Grid>
      </div>
      <Table />
    </Surface>
  );
};

export default Example;

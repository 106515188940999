import React from 'react';
import { Grid2 as MuiGrid } from '@mui/material';

import type { GridProps } from './Grid.types';

export const Grid = ({
  children,
  gap = '1rem',
  spacing = 2,
  columns = 12,
  padding = '1rem 0',
  size,
  alignItems = 'center',
  justifyContent = 'space-evenly',
  flexDirection = 'row',
  wrap = 'wrap',
  sizeEvenly = true,
}: GridProps): React.ReactElement => {
  const childrenArray = React.Children.toArray(children);

  const itemSize = sizeEvenly ? columns / childrenArray.length : size;

  return (
    <MuiGrid
      padding={padding}
      columns={columns}
      gap={gap}
      spacing={spacing}
      wrap={wrap}
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      container
    >
      {childrenArray.map((child, index) => (
        <MuiGrid size={itemSize} key={index}>
          {child}
        </MuiGrid>
      ))}
    </MuiGrid>
  );
};

import React from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput as MuiInput } from '@mui/material';

import type { InputProps } from './Input.types';

export const Input = ({ icon, label, placeholder }: InputProps): React.ReactElement => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiInput label={label} placeholder={placeholder} startAdornment={<InputAdornment position="start">{icon}</InputAdornment>} />
    </FormControl>
  );
};

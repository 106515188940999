import type { HttpMethodOptions } from './types';

import { customFetch } from './fetchInstance';

/**
 * Sends a GET request to the recruiting backend with the given path
 * @param path - The backend path
 * @param options - The options for the request
 * @returns - A Promise that resolves with the resources from the server
 */
// Disable because tsc won't compile without the comma
const get = async <TResp, >(path: string, options?: HttpMethodOptions): Promise<TResp> => {
  const { headers, query, signalKey } = options || {};

  return await customFetch(path, {
    method: 'GET',
    headers,
    query,
    signalKey,
  }) as TResp;
};

/**
 * Sends a POST request to the recruiting backend with the given path
 * @param path - The backend path
 * @param body - The data that's sent to the backend
 * @param options - The options for the request
 * @returns - A Promise that resolves with the server response
 */
const post = async <TBody, TResp>(path: string, body: TBody, options?: HttpMethodOptions): Promise<TResp> => {
  const { headers, query, signalKey } = options || {};

  return await customFetch(path, {
    method: 'POST',
    headers,
    query,
    body,
    signalKey,
  }) as TResp;
};

/**
 * Sends a DELETE request to the recruiting backend with the given path
 * @param path - The backend path
 * @returns - A Promise that resolves with the server response
 */
const remove = async (path: string): Promise<void> => {
  await customFetch(path, {
    method: 'DELETE',
  });
};

/**
 * Sends a PUT request to the recruiting backend with the given path and body
 * @param path - The backend path
 * @param body - The data that's sent to the backend
 * @param options - The options for the request
 * @returns - A Promise that resolves with the server response
 */
const put = async <TBody, TResp>(path: string, body?: TBody, options?: HttpMethodOptions): Promise<TResp> => {
  const { headers, query, signalKey } = options || {};

  return await customFetch(path, {
    method: 'PUT',
    headers,
    query,
    signalKey,
    body,
  }) as TResp;
};

/**
 * Sends a PATCH request to the recruiting backend with the given path and body
 * @param path - The backend path
 * @param body - The data that's sent to the backend
 * @param options - The options for the request
 * @returns - A Promise that resolves with the server response
 */
const patch = async <TBody, TResp>(path: string, body?: TBody, options?: HttpMethodOptions): Promise<TResp> => {
  const { headers, query, signalKey } = options || {};

  return await customFetch(path, {
    method: 'PATCH',
    headers,
    query,
    signalKey,
    body,
  }) as TResp;
};

const client = {
  delete: remove,
  patch: patch,
  post: post,
  put: put,
  get: get,
};

export default client;

import React from 'react';
import { Button as MuiButton } from '@mui/material';

import type { ButtonWithIconProps } from './ButtonWithIcon.types';

export const ButtonWithIcon = ({
  children,
  disabled,
  disableElevation,
  fullWidth,
  size,
  startIcon,
  variant,
  color,
}: ButtonWithIconProps): React.ReactElement => {
  return (
    <MuiButton
      disabled={disabled}
      disableElevation={disableElevation}
      fullWidth={fullWidth}
      size={size || 'large'}
      startIcon={startIcon}
      variant={variant || 'contained'}
      color={color || 'primary'}
    >
      {children}
    </MuiButton>
  );
};

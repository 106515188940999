import { QueryClient } from 'react-query';

import monitoring from '@services/monitoring';

import type { NavigateFunction } from 'react-router-dom';

export const createQueryClient = (navigate: NavigateFunction): QueryClient => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        staleTime: 60000, // Previously fetched data is considered fresh for 1 minute
        refetchOnMount: false, // Don't re-fetch if data is fresh
        cacheTime: 120000, // Unused data is cached for 2 minutes
        onError: (error): void => {
          const id = monitoring.captureException(error as Error);
          navigate(`/error?ticketId=${id}`);
        },
      },
      mutations: {
        onError: (error): void => {
          monitoring.captureException(error as Error);
        },
      },
    },
  });
};

import React from 'react';
import { toast } from 'react-hot-toast';
import { CircleCheck, CircleInfo, TriangleExclamation } from '@omnihub/ui/icons/filled';

import styling from './Notifications.module.scss';

/**
 * Displays an information notification.
 * @param title notification title
 * @param message notification message
 * @param duration duration for which the toast is shown in ms
 * @returns toast ID
 */
const info = (title: string, message: string, duration = 5000): string => {
  let toastId = '';

  const component = (): React.ReactElement => (
    <div className={styling.info} onClick={() => toast.remove(toastId)} role="button">
      <CircleInfo />

      <div>
        <div className={styling.title}>
          {title}
        </div>
        <div className={styling.message}>
          {message}
        </div>
      </div>
    </div>
  );

  toastId = toast(component, { className: styling.container, duration });

  return toastId;
};

/**
 * Displays a success notification.
 * @param title notification title
 * @param message notification message
 * @param duration duration for which the toast is shown in ms
 * @returns toast ID
 */
const success = (title: string, message: string, duration = 50000): string => {
  let toastId = '';

  const component = (): React.ReactElement => (
    <div className={styling.success} onClick={() => toast.remove(toastId)} role="button">
      <CircleCheck />

      <div>
        <div className={styling.title}>
          {title}
        </div>
        <div className={styling.message}>
          {message}
        </div>
      </div>
    </div>
  );

  toastId = toast(component, { className: styling.container, duration });

  return toastId;
};

/**
 * Displays an alert notification.
 * @param title notification title
 * @param message notification message
 * @param duration duration for which the toast is shown in ms
 * @returns toast ID
 */
const alert = (title: string, message: string, duration = 5000): string => {
  let toastId = '';

  const component = (): React.ReactElement => (
    <div className={styling.alert} onClick={() => toast.remove(toastId)} role="button">
      <TriangleExclamation />

      <div>
        <div className={styling.title}>
          {title}
        </div>
        <div className={styling.message}>
          {message}
        </div>
      </div>
    </div>
  );

  toastId = toast(component, { className: styling.container, duration });

  return toastId;
};

const dismiss = toast.dismiss;

export {
  dismiss,
  info,
  success,
  alert,
};

import * as Sentry from '@sentry/react';

import type { BrowserOptions } from '@sentry/react';

// These are the strings that Vite chunk
// load error messages start with
const chunkLoadErrorMsgPrefixes = [
  'error loading dynamically imported module',
  'Failed to fetch dynamically imported module',
  'Importing a module script failed',
  'Unable to preload CSS for',
];

// @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/
const config: BrowserOptions = {
  dsn: 'https://008201ee96881bfc4616722c09bb2e49@sentry.statec.io/123',
  enabled: window.location.hostname !== 'localhost',
  release: import.meta.env.VITE_APP_BUILD_VERSION || '',
  environment: 'production',
  sampleRate: 1,
  replaysOnErrorSampleRate: 1, // Enables video like records up to 1 minute prior to an error
  ignoreErrors: [
    // This is expected to happen occasionally. We handle it
    // in our global error boundary
    'Loading CSS chunk',
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  beforeSend: (event, hint) => {
    // Prevent sending chunk load errors as they are handled
    // by the error boundary
    const isChunkLoadError = chunkLoadErrorMsgPrefixes.some((prefix) => {
      return (hint.originalException as Error).message.includes(prefix);
    });

    if (isChunkLoadError) {
      return null;
    }

    return event;
  },
  initialScope: {
    tags: {
      namespace: window.location.host.split('.')[0] || 'n/a',
    },
  },
  integrations: [Sentry.replayIntegration()],
};

export default config;

import type { PaginatedEntryReadList } from '../../_generated';

import client from '../../client';
import { blocklistEntriesValidation, type BlocklistEntry } from './blocklistEntries.types.ts';

export const getAll = async (): Promise<BlocklistEntry[]> => {
  const response = await client.get<PaginatedEntryReadList>('/entries');

  return blocklistEntriesValidation.parse(response.results);
};

import { z } from 'zod';

import { CriteriaReadName, EntryReadReason, EntryReadTarget } from '../../_generated';

export const blocklistEntryValidation = z.object({
  id: z.number(),
  isActive: z.boolean(),
  deactivatedAt: z.string().nullable().transform((value) => (value ? new Date(value) : null)),
  reason: z.nativeEnum(EntryReadReason),
  target: z.nativeEnum(EntryReadTarget),
  user: z.number(),
  portal: z.number(),
  criteria: z.record(z.nativeEnum(CriteriaReadName), z.string()),
  createdAt: z.string().transform((value) => new Date(value)),
  updatedAt: z.string().transform((value) => new Date(value)),
});

export const blocklistEntriesValidation = z.array(blocklistEntryValidation);

export type BlocklistEntry = z.infer<typeof blocklistEntryValidation>;
import React, { useMemo } from 'react';
import { QueryClientProvider } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { Props } from './ReactQueryProvider.types.ts';

import { createQueryClient } from './ReactQueryProvider.helpers.ts';

const ReactQueryProvider = ({ children }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const queryClient = useMemo(() => createQueryClient(navigate), [navigate]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default ReactQueryProvider;

/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Aegis - Blocklist
 * Staff tool to manage various block criterias
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `backfill` - Backfill
* `partner` - Partner
 */
export enum EntryReadTarget {
  backfill= 'backfill',
  partner= 'partner',

}
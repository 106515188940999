const setAegisJWT = (jwt: string): void => {
  localStorage.setItem('th-aegis-jwt', jwt);
};

const getAegisJWT = (): string | null => {
  return localStorage.getItem('th-aegis-jwt');
};

const removeAegisJWT = (): void => {
  localStorage.removeItem('th-aegis-jwt');
};

export { setAegisJWT, getAegisJWT, removeAegisJWT };
